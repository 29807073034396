import { Injectable } from "@angular/core"
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http"

export interface dataStructure {
  meta?: any
  items: any[]
}

@Injectable({
  providedIn: "root",
})
export class CurriculumService {
  constructor(private http: HttpClient) {}

  public getAssets(baseURL) {
    return this.http.get(baseURL + "api/v2/assets/?format=json&limit=50")
  }

  public getPrograms(baseURL) {
    return this.http.get(baseURL + "api/v2/programs/?format=json&limit=50")
  }

  public getAudiences(baseURL) {
    return this.http.get(baseURL + "api/v2/audiences/?format=json&limit=50")
  }

  public getStandardsBodies(baseURL) {
    return this.http.get(
      baseURL + "api/v2/standards-body/?format=json&limit=50"
    )
  }

  public getStandards(baseURL) {
    return this.http.get(baseURL + "api/v2/standards/?format=json&limit=50")
  }

  public getLearningSpaces(baseURL) {
    return this.http.get(
      baseURL + "api/v2/learning-spaces/?format=json&limit=50"
    )
  }

  public getTopics(baseURL) {
    return this.http.get(baseURL + "api/v2/topics/?format=json&limit=50")
  }

  public getTags(baseURL) {
    return this.http.get(baseURL + "api/v2/tags/?format=json&limit=50")
  }

  public getAssetTypes(baseURL) {
    return this.http.get(baseURL + "api/v2/asset-type/?format=json&limit=50")
  }

  public getActivityTypes(baseURL) {
    return this.http.get(baseURL + "api/v2/activity-type/?format=json&limit=50")
  }

  public getTimeEstimates(baseURL) {
    return this.http.get(
      baseURL + "api/v2/time-estimates/?format=json&limit=50"
    )
  }

  public getModules(baseURL) {
    return this.http.get(baseURL + "api/v2/modules/?format=json&limit=50")
  }

  public getLessons(baseURL) {
    return this.http.get(baseURL + "api/v2/lessons/?format=json&limit=50")
  }

  public getActivities(baseURL) {
    return this.http.get(baseURL + "api/v2/activities/?format=json&limit=50")
  }
}
