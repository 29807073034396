<!-- Modal -->
<div class="modal fade" id="standardsModal" tabindex="-1" role="dialog" aria-labelledby="standardsModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="standardsModal">Select Standards to filter by.</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col" *ngFor="let standard_body of standards">
            <h5>{{ standard_body.standards_body }}</h5>
            <ul id="standards_list" class="list-group select-list">
              <li *ngFor="let t of standard_body.standards" class="standard-item list-group-item p-0 border-0">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input"
                            (change)="getSelectedStandards()"
                            type="checkbox"
                            id="standard_{{t.id}}"
                            name="standard"
                            value="{{t.id}}"
                            [(ngModel)]="t.selected"/>
                  <label class="custom-control-label topic-text text-muted ml-2" for="standard_{{t.id}}"><small style="font-weight: lighter !important;">{{t.standard}}</small></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-md-3">
    <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1" class="border-bottom">
      <!-- Program Panel -->
      <ngb-panel id="custom-panel-1">
        <ng-template ngbPanelHeader let-opened="opened" class="bg-white">
          <div class="d-flex align-items-center justify-content-between">
            <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
              <h5 class="mt-4">
                Program
                <fa-icon *ngIf="!opened" class="float-right" [icon]="this.faPlus"></fa-icon>
                <fa-icon *ngIf="opened" class="float-right" [icon]="this.faMinus"></fa-icon>
              </h5>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ul id="program-list" class="list-group select-list">
            <li *ngFor="let p of programs" class="program-item list-group-item p-0 border-0">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input"
                          (change)="getSelectedPrograms()"
                          type="checkbox"
                          id="program_{{p.id}}"
                          name="program"
                          value="{{p.id}}"
                          [(ngModel)]="p.selected"/>
                <label class="custom-control-label topic-text text-muted ml-lg-2" for="program_{{p.id}}">{{p.program_name}}</label>
              </div>
            </li>
          </ul>
        </ng-template>
      </ngb-panel>
      <!-- Audience Panel -->
      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between">
            <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
              <h5 class="mt-4">
                Audience
                <fa-icon *ngIf="!opened" class="float-right" [icon]="this.faPlus"></fa-icon>
                <fa-icon *ngIf="opened" class="float-right" [icon]="this.faMinus"></fa-icon>
              </h5>
            </button>
            <div>
              <!-- <button type="button" class="btn btn-sm btn-outline-secondary ml-2" (click)="disabled = !disabled">
                {{ disabled ? 'En' : 'Dis' }}able third
              </button>
              <button type="button" class="btn btn-sm btn-outline-danger ml-2" (click)="a.collapseAll()">Collapse all</button> -->
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ul id="audience-list" class="list-group select-list">
            <li *ngFor="let a of audiences" class="audience-item list-group-item p-0 border-0">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input"
                          (change)="getSelectedAudiences()"
                          type="checkbox"
                          id="audience_{{a.id}}"
                          name="audience"
                          value="{{a.id}}"
                          [(ngModel)]="a.selected"/>
                <label class="custom-control-label topic-text text-muted ml-2" for="audience_{{a.id}}">{{a.age_range}}</label>
              </div>
            </li>
          </ul>
        </ng-template>
      </ngb-panel>
      <!-- Learning Space Panel -->
      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between">
            <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
              <h5 class="mt-4">
                Learning Space
                <fa-icon *ngIf="!opened" class="float-right" [icon]="this.faPlus"></fa-icon>
                <fa-icon *ngIf="opened" class="float-right" [icon]="this.faMinus"></fa-icon>
              </h5>
            </button>
            <div>
              <!-- <button type="button" class="btn btn-sm btn-outline-secondary ml-2" (click)="disabled = !disabled">
                {{ disabled ? 'En' : 'Dis' }}able third
              </button>
              <button type="button" class="btn btn-sm btn-outline-danger ml-2" (click)="a.collapseAll()">Collapse all</button> -->
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ul id="learningspace-list" class="list-group select-list">
            <li *ngFor="let a of learning_spaces" class="learningspace-item list-group-item p-0 border-0">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input"
                          (change)="getSelectedLearningSpaces()"
                          type="checkbox"
                          id="learningspace_{{a.id}}"
                          name="learning_space"
                          value="{{a.id}}"
                          [(ngModel)]="a.selected"/>
                <label class="custom-control-label topic-text text-muted ml-2" for="learningspace_{{a.id}}">{{a.learning_space}}</label>
              </div>
            </li>
          </ul>
        </ng-template>
      </ngb-panel>
      <!-- Time Estimates Panel -->
      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between">
            <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
              <h5 class="mt-4">
                Time Estimates
                <fa-icon *ngIf="!opened" class="float-right" [icon]="this.faPlus"></fa-icon>
                <fa-icon *ngIf="opened" class="float-right" [icon]="this.faMinus"></fa-icon>
              </h5>
            </button>
            <div>
              <!-- <button type="button" class="btn btn-sm btn-outline-secondary ml-2" (click)="disabled = !disabled">
                {{ disabled ? 'En' : 'Dis' }}able third
              </button>
              <button type="button" class="btn btn-sm btn-outline-danger ml-2" (click)="a.collapseAll()">Collapse all</button> -->
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ul id="timeestimates-list" class="list-group select-list">
            <li *ngFor="let a of time_estimates" class="timeestimates-item list-group-item p-0 border-0">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input"
                          (change)="getSelectedTimeEstimates()"
                          type="checkbox"
                          id="timeestimates_{{a.id}}"
                          name="time_estimate"
                          value="{{a.id}}"
                          [(ngModel)]="a.selected"/>
                <label class="custom-control-label topic-text text-muted ml-2" for="timeestimates_{{a.id}}">{{a.time_estimate}}</label>
              </div>
            </li>
          </ul>
        </ng-template>
      </ngb-panel>
      <!-- Topic Panel -->
      <ngb-panel [disabled]="disabled" [cardClass]="disabled ? 'disabled' : ''">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between">
            <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
              <h5 class="mt-4">
                Topic
                <fa-icon *ngIf="!opened" class="float-right" [icon]="this.faPlus"></fa-icon>
                <fa-icon *ngIf="opened" class="float-right" [icon]="this.faMinus"></fa-icon>
              </h5>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ul id="topic-list" class="list-group select-list">
            <li *ngFor="let t of topics" class="topic-item list-group-item p-0 border-0">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input"
                          (change)="getSelectedTopics()"
                          type="checkbox"
                          id="topic_{{t.id}}"
                          name="topic"
                          value="{{t.id}}"
                          [(ngModel)]="t.selected"/>
                <label class="custom-control-label topic-text text-muted ml-2" for="topic_{{t.id}}">{{t.topic}}</label>
              </div>
            </li>
          </ul>
        </ng-template>
      </ngb-panel>
      <!-- Activity Type Panel -->
      <!-- <ngb-panel [disabled]="disabled" [cardClass]="disabled ? 'disabled' : ''">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between">
            <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
              <h5 class="mt-4">
                Type of Lesson
                <fa-icon *ngIf="!opened" class="float-right" [icon]="this.faPlus"></fa-icon>
                <fa-icon *ngIf="opened" class="float-right" [icon]="this.faMinus"></fa-icon>
              </h5>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ul id="activity_type_list" class="list-group select-list">
            <li *ngFor="let t of activity_types" class="activity-type-item list-group-item p-0 border-0">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input"
                          (change)="getSelectedActivityTypes()"
                          type="checkbox"
                          id="activity_type_{{t.id}}"
                          name="activity_type"
                          value="{{t.id}}"
                          [(ngModel)]="t.selected"/>
                <label class="custom-control-label topic-text text-muted ml-2" for="activity_type_{{t.id}}">{{t.activity_type}}</label>
              </div>
            </li>
          </ul>
        </ng-template>
      </ngb-panel> -->
      <!-- Asset Type Panel -->
      <ngb-panel [disabled]="disabled" [cardClass]="disabled ? 'disabled' : ''">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-between">
            <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
              <h5 class="mt-4">
                Type of Asset
                <fa-icon *ngIf="!opened" class="float-right" [icon]="this.faPlus"></fa-icon>
                <fa-icon *ngIf="opened" class="float-right" [icon]="this.faMinus"></fa-icon>
              </h5>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ul id="asset_type_list" class="list-group select-list">
            <li *ngFor="let t of asset_types" class="asset-type-item list-group-item p-0 border-0">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input"
                          (change)="getSelectedAssetTypes()"
                          type="checkbox"
                          id="asset_type_{{t.id}}"
                          name="asset_type"
                          value="{{t.id}}"
                          [(ngModel)]="t.selected"/>
                <label class="custom-control-label topic-text text-muted ml-2" for="asset_type_{{t.id}}">{{t.asset_type}}</label>
              </div>
            </li>
          </ul>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>


      <!-- Button trigger modal -->
      <button type="button" class="btn btn-link ml-2 mt-3" data-toggle="modal" data-target="#standardsModal">
        <h5>Filter by Standards</h5>
      </button>
  </div>

  <div class="col-md-9 pt-2">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink><h5 class="my-lg-4 mx-lg-3">Modules</h5></a>
        <ng-template ngbNavContent>
          <div id="module_container">
            <ul id="module_search_results" class="list-group search-results" *ngFor="let module of this.modules | programs: selected_programs | audiences: selected_audiences | tag: selected_tags | topic: selected_topics | learningSpace: selected_learning_spaces | timeEstimate: selected_time_estimates | standard: selected_standards">
              <li class="list-group-item border-0">
                <a href="{{ baseURL }}curriculum/modules/{{ module.id }}-{{ module.slug }}">
                  <h3 class="h3">
                    {{ module.title }}<ng-container *ngIf="module.subtitle">: {{ module.subtitle }}</ng-container>
                  </h3>
                  <p [innerHTML]="module.intro_copy | safe: 'html'"></p>
                </a>
              </li>
            </ul>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink><h5 class="my-lg-4 mx-lg-3">Lessons</h5></a>
        <ng-template ngbNavContent>
          <div id="activity_container">
            <!-- <ul class="list-group" *ngFor="let activity of this.activities | programs: selected_programs | audiences: selected_audiences | tag: selected_tags | topic: selected_topics | activityType: selected_activity_types | learningSpace: selected_learning_spaces | timeEstimate: selected_time_estimates"> -->
              <ul class="list-group" *ngFor="let activity of this.activities | programs: selected_programs | audiences: selected_audiences | tag: selected_tags | topic: selected_topics | learningSpace: selected_learning_spaces | timeEstimate: selected_time_estimates">
              <ng-container>
                <li class="list-group-item border-0">
                  <a href="{{ baseURL }}curriculum/activity/{{ activity.id }}-{{ activity.slug }}" target="_blank">
                    <h3 class="h3">
                      {{ activity.title }}
                    </h3>
                    <p [innerHTML]="activity.overview_copy | safe: 'html'"></p>
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink><h5 class="my-lg-4 mx-lg-3">Assets</h5></a>
        <ng-template ngbNavContent>
          <div id="asset_container" *ngIf=!modulesOnly>
            <p class="text-center" *ngIf='selected_asset_count > 0'>There are no assets associated with your selection. Remove some of your filters to find additional assets.</p>
            <ul class="list-group" *ngFor="let asset of this.assets | programs: selected_programs | audiences: selected_audiences | tag: selected_tags | topic: selected_topics | assetType: selected_asset_types">
              <li class="list-group-item border-0">
                <a href="{{ baseURL }}curriculum/assets/{{ asset.id }}-{{ asset.slug }}" target="_blank">
                  <h3 class="h3">
                    {{ asset.title }}
                  </h3>
                  <p [innerHTML]="asset.description | safe: 'html'"></p>
                </a>
              </li>
            </ul>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>

<!--modal-->
<script>
  $(document).ready(function() {
    $("#exampleModalLong").modal();
  });
</script>
